import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    hasActiveGame: false,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    questionOne: null,
    questionTwo: null,
    questionThree: null,
    questionFour: null,
    questionGdpr: null,
    savedData: null,
    activeGameDiff: null,
    activeGameUser: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getFirstName(state) {
      return state.firstName;
    },
    getLastName(state) {
      return state.lastName;
    },
    getPhone(state) {
      return state.phone;
    },
    getEmail(state) {
      return state.email;
    },
    getQuestionOne(state) {
      return state.questionOne;
    },
    getQuestionTwo(state) {
      return state.questionTwo;
    },
    getQuestionThree(state) {
      return state.questionThree;
    },
    getQuestionGdpr(state) {
      return state.questionGdpr;
    },
    getQuestionFour(state) {
      return state.questionFour;
    },
    getSavedData(state) {
      return state.savedData;
    },
    getActiveGameDiff(state) {
      return state.activeGameDiff;
    },
    getActiveGameUser(state) {
      return state.activeGameUser;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setPhone(state, phone) {
      state.phone = phone;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setQuestionOne(state, questionOne) {
      state.questionOne = questionOne;
    },
    setQuestionTwo(state, questionTwo) {
      state.questionTwo = questionTwo;
    },
    setQuestionThree(state, questionThree) {
      state.questionThree = questionThree;
    },
    setQuestionFour(state, questionFour) {
      state.questionFour = questionFour;
    },
    setQuestionGdpr(state, questionGdpr) {
      state.questionGdpr = questionGdpr;
    },
    setSavedData(state, savedData) {
      state.savedData = savedData;
    },
    setActiveGameDiff(state, diff) {
      state.activeGameDiff = diff;
    },
    setActiveGameUser(state, diff) {
      state.activeGameUser = diff;
    },
  },
  actions: {
    saveVisitor(state, data) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_TOKEN;
      axios
        .post(
          process.env.VUE_APP_COCKPITURL + "/api/content/item/visitors",
          {
            data: data,
          },
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setSavedData", response.data);
        })
        .catch((err) => console.log(err));
    },
    getActiveGame(state) {
      state.commit("setLoading", true);
      // let token = process.env.VUE_APP_TOKEN;
      axios
        .get(process.env.VUE_APP_SCRIPTURL + "/getActiveGame/", {
          // params: {
          //   limit: 1,
          //   sort: "{'_modified': -1 }",
          //   filter: "{'scanned': true }",
          // },
          // headers: {
          //   "api-key": token,
          // },
        })
        .then((response) => {
          state.commit("setLoading", false);
          let modified = response.data[0]._modified;
          let current = Math.round(Date.now() / 1000);
          let diff = current - modified;
          state.commit("setActiveGameUser", response.data[0]);
          state.commit("setActiveGameDiff", diff);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
