<template>
  <div class="vh-100 outer home-featured" style="text-align: center">
    <div class="center">
      <h1>Scan mij</h1>
      <img
        :src="'https://clawmachine.nl/qrcode/?s=qr&sf=8&d=' + getUrl()"
        :title="getUrl()"
        width="350"
      />
      <h1>voor een</h1>
      <h1>gratis spel</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "InactiveScreen",
  components: {},
  methods: {
    getUrl() {
      return process.env.VUE_APP_GAMEURL;
    },
  },
};
</script>

<style>
h1 {
  font-size: 120px;
  color: #fff;
  padding-bottom: 20px;
  font-weight: 500;
}
img {
  padding-bottom: 20px;
}
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.screen {
  /* background: url("~@/assets/home_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%; */
  overflow: hidden; /* Hide scrollbars */
}
.home-featured {
  font-weight: 200;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(#00b6ff, #39f, #00b6ff);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 0.5s ease infinite;
  animation: gradanimate 10s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
</style>
